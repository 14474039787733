const constants = {
  BASE_URL: process.env.NODE_ENV === 'production' ? 'https://app.reddotbespoke.com' : process.env.NODE_ENV === 'staging' ? 'https://staging.reddotbespoke.com' : 'http://localhost:8080',
  SEARCH_TIMEOUT_IN_MS: 800,
  BACKEND_URL: process.env.GATSBY_API_URL || process.env.GATSBY_STAGING_API_URL || 'http://localhost:3000',
  SETMORE_TOKEN: process.env.GATSBY_SETMORE_TOKEN || '',
  STATUS_COLLECTED: 'Collected',
  STATUS_END_FITTING: 'EndFitting',
  REFUND_STATUS: {
    PENDING: 'pending',
    APPROVED: 'approved',
    REJECTED: 'rejected',
  },
  MIN_BUFFER_DAYS: {
    "SS": 6,
    "LS": 6,
    "SP": 6,
    "LP": 6,
    "SBJ": 12,
    "DBJ": 12,
    "SBV": 7,
    "DBV": 7,
    "PRIMO SS": 6,
    "PRIMO LS": 6,
    "PRIMO SP": 6,
    "PRIMO LP": 6,
    "PRIMO SBJ": 12,
    "PRIMO DBJ": 12,
    "PRIMO SBV": 7,
    "PRIMO DBV": 7,
  },
  ITEM_TYPES: [
    "SS",
    "LS",
    "SP",
    "LP",
    "SBJ",
    "DBJ",
    "SBV",
    "DBV",
    "PRIMO SS",
    "PRIMO LS",
    "PRIMO SP",
    "PRIMO LP",
    "PRIMO SBJ",
    "PRIMO DBJ",
    "PRIMO SBV",
    "PRIMO DBV"
  ],
  ITEM_TYPES_GROUPED_OPTIONS: [
    {
      label: 'SHIRT',
      options: [
        { value: 'SS', label: 'SS' },
        { value: 'PRIMO SS', label: 'PRIMO SS' },
        { value: 'LS', label: 'LS' },
        { value: 'PRIMO LS', label: 'PRIMO LS' },
      ],
    },
    {
      label: 'PANTS',
      options: [
        { value: 'SP', label: 'SP' },
        { value: 'PRIMO SP', label: 'PRIMO SP' },
        { value: 'LP', label: 'LP' },
        { value: 'PRIMO LP', label: 'PRIMO LP' },
      ],
    },
    {
      label: 'JACKET',
      options: [
        { value: 'SBJ', label: 'SBJ', color: '#00B8D9', isFixed: true },
        { value: 'DBJ', label: 'DBJ', color: '#0052CC'},
        { value: 'PRIMO SBJ', label: 'PRIMO SBJ', color: '#00B8D9', isFixed: true },
        { value: 'PRIMO DBJ', label: 'PRIMO DBJ', color: '#0052CC'},
      ],
    },
    {
      label: 'VEST',
      options: [
        { value: 'SBV', label: 'SBV', color: '#00B8D9', isFixed: true },
        { value: 'PRIMO SBV', label: 'PRIMO SBV', color: '#0052CC'},
        { value: 'DBV', label: 'DBV', color: '#00B8D9', isFixed: true },
        { value: 'PRIMO DBV', label: 'PRIMO DBV', color: '#0052CC'},
      ],
    },
  ],
  COMMON_STATUS_ARRAY: [
    "FabricOrdered",
    "FabricReceived",
    "Processing",
    "QCFail",
    "Refit",
    "Fitting",
    "Altering",
    "Collection"
  ],
  passwordRegex: `^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$`,
  SLIM_FIT_ADVISORY_TEXT: 'Clients who prefer a slender look that accentuated his/her physic. The client will feel the fabric closer to his/her skin. This fit is only recommended for gentle movements or actions. Frequent raising or bending of arms due to carrying loads, squatting, or seating on a low bench may cause the client to feel tensions at the joints of arms and legs.',
  REGULAR_FIT_ADVISORY_TEXT: 'Clients who prefer a modern look with just enough room for comfort. This fit is recommended for clients who wish to achieve the best of both worlds.',
  COMFORT_FIT_ADVISORY_TEXT: 'Clients who prefer the ease of moving around freely in their clothing. He/She may not like the sensation of having the fabric too close to their skin. Best suited for frequent raising or bending of arms due to carrying or lifting loads, squatting, kneeling or seating cross-legged on flat ground.',
  MEASUREMENT_POINT_INFO: {
    COLLAR: `Base of neck around nape, shoulder neck point and below adam's apple`,
    CHEST_BACK: `Back mid of armhole`,
    CHEST_FRONT: `Front mid of armhole`,
    CHEST: `At nipple height/bust line, tape parallel to the ground`,
    STOMACH: `SLIM: 2 inch above belly button, PORTLY: Take on belly button`,
    BB_WAIST: `Cannot be smaller than waist.`,
    SLEEVE_LENGTH: `Hands held on waist, Shoulder point to below wrist bone`,
    SHORT_SLEEVE_LENGTH: `Recommended 2 inch above elbow'`,
    WRIST: `Below wrist bone/ With Watch`,
    HIP: `Around hip at widest part, tape parallel to the ground`,
    VEST_BOTTOM_NORMAL_WAIST: `About 2 inch below belly button`,
    VEST_BOTTOM_HIGH_WAIST: `According to client's pants height`,
    OPTIONAL: `Optional`,
    SEAT_RISE_NORMAL: `Seated Upright on flat stool, Back waistband to base of seat`,
    SEAT_RISE_HIGH: `Seated Upright on flat stool, Back waistband to base of seat`,
    WAIST_TO_HIP_NORMAL_MALE: `**auto-calculate, 3/4 of Seat Rise`,
    WAIST_TO_HIP_HIGH_MALE: `**auto-calculate, Difference of Seat Rise (High-Normal) plus Waist to Hip Normal`,
    WAIST_TO_HIP_NORMAL_FEMALE: `**auto-calculate, 4/5 of Seat Rise, Only when gender equal Female`,
    WAIST_TO_HIP_HIGH_FEMALE: `**auto-calculate, Difference of Seat Rise (High-Normal) plus Waist to Hip Normal`,
    BACK_WAIST_HEIGHT: `Back Stomach height to waistband`,
    FRONT_WAIST_HEIGHT: `Front Stomach height to waistband`,
    LP_LENGTH: `Below ankle bone`,
    LP_INSEAM: `Inner crotch to below ankle bone`,
    SP_LENGTH: `1.5" above knee bone`,

  }
}

export default constants